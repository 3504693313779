export enum EditorInteractionName {
  OPEN_CONNECT_PAYMENT_METHODS_MODAL = 'pay-button-open-connect-payment-methods-modal-window',
  PAY_BUTTON_OPEN_SETTINGS = 'pay-button-open-settings',
  FIRST_INSTALL_APP_MARKET = 'pay-button-first-install-app-market',
  FIRST_INSTALL_ADD_PANEL = 'pay-button-first-install-add-panel',
  UNINSTALL_APP = 'pay-button-uninstall-app',
}

export enum ViewerInteractionName {
  PAY_BUTTON_OPEN_PAY_APP = 'pay-button-open-payments-app',
  OPEN_PREMIUM_POPUP = 'pay-button-open-upgrade-and-test-place-test-modal',
  OPEN_NO_PAYMENTS_POPUP = 'pay-button-open-no-payments-popup',
}
