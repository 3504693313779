import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { uouPayButtonClick } from '@wix/bi-logger-payments-data/v2';
import { PayAppBIParams, MessageFromPopup } from '../../types';
import { APP_ID, MODAL_SIZE, buttonRole } from '../../utils/constants';
import {
  getNoPaymentsPopupUrl,
  getUpgradeAndTestPaymentModel,
} from '../../utils/links';
import { getPremiumStatus, makeRequestForOrder } from '../../utils/api';
import { isPreview } from '../../utils/states';
import { ViewerInteractionName } from '../../enums/Interaction';
import { getBiLogger } from '../../utils/biLogger';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  return {
    async pageReady() {
      const {
        httpClient,
        controllerConfig: { wixCodeApi: wixCode },
        fedops,
      } = flowAPI;

      const webBiLogger = controllerConfig.platformAPIs.biLoggerFactory?.();

      const biLogger = getBiLogger(
        {
          appid: APP_ID,
          appInstanceId: controllerConfig.appParams.appInstanceId,
          memberId: controllerConfig.platformAPIs.bi?.siteMemberId,
          msid: controllerConfig.platformAPIs.bi?.metaSiteId,
          origin: controllerConfig.wixCodeApi.location.url,
          paybuttonid: controllerConfig.appParams.instanceId,
          sessionId: controllerConfig.platformAPIs.bi?.viewerSessionId,
          visitorId: controllerConfig.platformAPIs.bi?.visitorId,
        },
        webBiLogger!,
      );

      const instance = wixCode.site.getAppToken?.(APP_ID) as string;
      // @ts-expect-error
      const { productId, termsAndConditionsLink } = controllerConfig.config;

      const button = controllerConfig.$w(`@${buttonRole}`);
      const paymentOptions = termsAndConditionsLink
        ? { termsAndConditionsLink }
        : {};
      const viewMode = wixCode.window.viewMode;

      const payAppBiParams: PayAppBIParams = {
        sessionId: controllerConfig.platformAPIs.bi?.viewerSessionId ?? '',
        pbId: controllerConfig.appParams.instanceId,
        pbOrigin: controllerConfig.wixCodeApi.location.url,
        siteMemberId: controllerConfig.platformAPIs.bi?.siteMemberId ?? '',
      };

      const startPayment = (orderId: string) => {
        // @ts-expect-error
        return wixCode.pay.startPayment(orderId, {
          ...paymentOptions,
          ...payAppBiParams,
        });
      };

      const deviceType = controllerConfig.wixCodeApi.window.formFactor;

      const openUpgradeAndPlaceTestModal = async (orderId: string) => {
        const event = await wixCode.window.openModal(
          getUpgradeAndTestPaymentModel(instance),
          {
            width: MODAL_SIZE[deviceType].width,
            height: MODAL_SIZE[deviceType].height,
            theme: 'BARE',
          },
        );

        if (event && event.message === MessageFromPopup.PlaceTestOrder) {
          return startPayment(orderId);
        }
      };

      const openNoPaymentsPopup = () => {
        return wixCode.window.openModal(
          getNoPaymentsPopupUrl({
            instance,
            referralInfo: 'payButton',
            startLoadTime: Date.now(),
          }),
          {
            width: MODAL_SIZE[deviceType].width,
            height: MODAL_SIZE[deviceType].height,
            theme: 'BARE',
          },
        );
      };

      const onButtonClick = async () => {
        biLogger.report(uouPayButtonClick({}));

        const { orderId } = await makeRequestForOrder(
          httpClient,
          instance,
          productId,
        );

        const isPremium = await getPremiumStatus(httpClient, instance);

        if (isPremium) {
          fedops.interactionStarted(
            ViewerInteractionName.PAY_BUTTON_OPEN_PAY_APP,
          );
          return startPayment(orderId);
        }

        if (!isPremium && isPreview(viewMode)) {
          fedops.interactionStarted(ViewerInteractionName.OPEN_PREMIUM_POPUP);
          return openUpgradeAndPlaceTestModal(orderId);
        }

        if (!isPremium) {
          fedops.interactionStarted(
            ViewerInteractionName.OPEN_NO_PAYMENTS_POPUP,
          );
          return openNoPaymentsPopup();
        }
      };

      button.onClick(async () => {
        try {
          return await onButtonClick();
        } catch (error) {
          console.error(error);
          flowAPI.reportError(error as Error);
        }
      });
    },
  };
};

export default createController;
