import { POP_UP_LINK, NO_PAYMENTS_POP_UP_LINK } from './constants';

export const getUpgradeAndTestPaymentModel = (instance: string): string =>
  `${POP_UP_LINK}?instance=${instance}&referralInfo=payApiPaymentsPopup&startLoadTime=${Date.now()}`;

export const getPremiumStatusLink = (): string =>
  `/_serverless/payments-checkout-server/pay-button/premiumStatus`;

export const getBusinessDataLink = (): string =>
  `/_api/site-properties-service/properties?fields.paths=paymentCurrency`;

export const getNoPaymentsPopupUrl = ({
  instance,
  referralInfo,
  startLoadTime,
}: {
  instance: string;
  referralInfo?: string;
  startLoadTime?: number;
}): string =>
  `${NO_PAYMENTS_POP_UP_LINK}?instance=${instance}&referralInfo=${referralInfo}&startLoadTime=${startLoadTime}`;
