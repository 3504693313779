import { IHttpClient } from '@wix/yoshi-flow-editor';
import { ORDER_API_PATH, PRODUCT_API_PATH } from './constants';
import { getBusinessDataLink, getPremiumStatusLink } from './links';
import type {
  CreateProductRequest,
  CreateProductResponse,
  GetProductResponse,
  UpdateProductRequest,
  CreateOrderRequest,
  UpdateProductResponse,
  CreateOrderResponse,
} from '@wix/ambassador-payment-paybutton-web/http';
import type { PropertiesReadResponse } from '@wix/ambassador-site-properties-public-web/http';

export async function makeRequestForOrder(
  httpClient: IHttpClient,
  instance: string,
  productId: string,
) {
  const data: CreateOrderRequest = { productId };
  return httpClient
    .post<CreateOrderResponse>(ORDER_API_PATH, data, {
      headers: { Authorization: instance },
    })
    .then((resp) => ({ orderId: resp.data.orderId! }));
}

export const createProduct = (
  httpClient: IHttpClient,
  instance: string,
  data: CreateProductRequest,
) => {
  return httpClient
    .post<CreateProductResponse>(PRODUCT_API_PATH, data, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data);
};

export const getProduct = (
  httpClient: IHttpClient,
  instance: string,
  productId: string,
) => {
  return httpClient
    .get<GetProductResponse>(`${PRODUCT_API_PATH}/${productId}`, {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data);
};

export const updateProduct = async (
  httpClient: IHttpClient,
  instance: string,
  data: UpdateProductRequest,
  productId: string,
) => {
  await httpClient.put<UpdateProductResponse>(
    `${PRODUCT_API_PATH}/${productId}`,
    data,
    {
      headers: { Authorization: instance },
    },
  );
};

export const updateProductCurrencyFromSettings = async (
  httpClient: IHttpClient,
  instance: string,
  productId: string,
) => {
  const [productData, { paymentCurrency: currency }] = await Promise.all([
    getProduct(httpClient, instance, productId),
    getBusinessData(httpClient, instance),
  ]);
  // NOTE: fallback for rare cases when currency is not set (PAY-23787)
  productData.product!.currency = currency || 'USD';
  await updateProduct(
    httpClient,
    instance,
    { product: productData.product!, productId },
    productId,
  );
};

export const getBusinessData = (httpClient: IHttpClient, instance: string) => {
  return httpClient
    .get<PropertiesReadResponse>(getBusinessDataLink(), {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data.properties!);
};

export const getPremiumStatus = (
  httpClient: IHttpClient,
  instance: string,
): Promise<boolean> => {
  return httpClient
    .get<{ canAcceptOnlinePayments: boolean }>(getPremiumStatusLink(), {
      headers: { Authorization: instance },
    })
    .then((resp) => resp.data.canAcceptOnlinePayments)
    .catch(() => true);
};
