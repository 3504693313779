import type { Factory } from '@wix/web-bi-logger/dist/src/logger';

export const getBiLogger = (
  biLoggerDefaults: {
    [key: string]: string | number | undefined;
  },
  webBiLogger: Factory,
) => {
  const biLogger = webBiLogger
    .updateDefaults({
      sessionId: 'exampleStr',
      ...biLoggerDefaults,
    })
    .logger();
  return biLogger;
};
