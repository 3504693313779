export const TOKEN = 'token'; // deprecated unused param for editorSDK
export const CONTROLLER_TYPE = 'WixPay';
export const ORDER_API_PATH = '/_api/payment-paybutton-web/paybutton/v2/orders';
export const PRODUCT_API_PATH =
  '/_api/payment-paybutton-web/paybutton/v2/products';
export const APP_ID = 'cdd4b6d5-6fb4-4bd1-9189-791244b5361e';
export const POP_UP_LINK =
  'https://cashier.wixapps.net/payment_app/premium-popup';
export const NO_PAYMENTS_POP_UP_LINK =
  'https://cashier.wixapps.net/payment_app/no-payments-popup';
export const panelHelpId = '48d2426a-56ca-4b09-ba4b-1f709ad6debb';

export const buttonRole = 'PayButton';

enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export const MODAL_SIZE = {
  [DeviceType.Desktop]: {
    width: 750,
    height: 550,
  },
  [DeviceType.Tablet]: {
    width: 750,
    height: 550,
  },
  // workaround to open modal to 100%, openModal doesn't support string values
  // https://wix.slack.com/archives/CAKBA7TDH/p1604926273350300?thread_ts=1604922926.343800&cid=CAKBA7TDH
  [DeviceType.Mobile]: {
    width: 4000,
    height: 4000,
  },
};

export const createDefaultProduct = (currency: string, name: string) => ({
  product: {
    currency,
    name,
    price: 1,
  },
});
